@font-face {
    font-family: 'Alegreya';
    src: url('./fonts/Alegreya-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Caslon 540 BT';
    src: url('./fonts/CaslonC540BT-Bold.otf') format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Caslon 540 BT';
    src: url('./fonts/CaslonC540BT-Italic.otf') format("opentype");
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Circe';
    src: url('./fonts/Circe-Bold.otf') format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Circe';
    src: url('./fonts/Circe-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Circe';
    src: url('./fonts/Circe-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Old Standard Regular';
    src: url('./fonts/OldStandard-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

html,
body,
* {
    font-family: 'Circle','Alegreya', 'Alegreya SC', 'Caslon 540 BT', 'Old Standard Regular';
}